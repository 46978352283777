import React, { FC, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../styles/theme';

import OrbContainer from '../../components/OrbContainer';
import useFormFactors from '../../helpers/useFormFactors';

import blob from '../../images/blobs/blob.png';

const Layout = styled.section`
  display: flex;
  flex-direction: column;
  padding: 64px 16px;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 32px 0;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 40px 0;
  }
`;

const Section = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
  }
`;

const Body = styled.div`
  padding: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
`;

const Title = styled.p`
  color: ${(props) => props.theme.colors.dirtOrange};
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  padding: 0 0 24px 0;
  text-align: center;
  z-index: 1;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 16px;
    line-height: 20px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
    line-height: 20px;
    padding: 0 0 24px 16px;
  }
`;
const Heading = styled.p`
  color: ${(props) => props.theme.colors.blackText};
  font-size: 36px;
  font-weight: 600;
  line-height: 48px;
  margin: 0 -16px 16px -16px;
  text-align: center;
  max-width: 316px;



  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 48px;
    line-height: 52px;
    margin: 0 auto;
    max-width: 420px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 64px;
    line-height: 70px;
    max-width: 560px;
  }
`;

const Paragraph = styled.p`
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin: 24px 0 16px 0;
  text-align: center;
  max-width: 460px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 16px;
    line-height: 22px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
    line-height: 26px;
    margin: 0;
  }
`;

const Blob = styled.img`
  height: 905px;
  left: -232px;
  position: absolute;
  top: -180px;
  width: 849px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    height: 745px;
    left: -28%;
    position: absolute;
    top: -5%;
    width: 763px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    height: 800px;
    left: -80px;
    position: absolute;
    top: -240px;
    width: 800px;
  }
`;

const HeaderSection: FC = () => {
  const [blobElement, setBlobElement] = useState<HTMLImageElement>();

  const formFactors = useFormFactors();

  const blobRef = useCallback((element: HTMLImageElement) => {
    setBlobElement(element);
  }, []);

  const orbMovementMultiplier = useMemo(
    () => (formFactors.mobile ? 10 : formFactors.tablet ? 15 : 20),
    []
  );

  return (
    <Layout>
      <OrbContainer
        childElement={blobElement}
        multiplier={orbMovementMultiplier}
        style={{ zIndex: 1 }}
      >
        <Blob alt='' src={blob} ref={blobRef} />
      </OrbContainer>
      <Section style={{ zIndex: 1 }}>
        <Body>
          <Title>How it Works</Title>
          <Heading>
            A simple and delightful experience
          </Heading>
          <Paragraph>
            We make it easy to celebrate and uplift those special people in your life. Get started in four simple steps...
          </Paragraph>
        </Body>
      </Section>
    </Layout>
  );
};

export default HeaderSection;
