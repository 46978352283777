import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { motion } from 'framer-motion';
import MetaTags from '../components/MetaTags';
import { theme } from '../styles/theme';
import Background from '../components/Background';
import Header from '../components/Header2';
import HeaderSection from '../sections/how-it-works/HeaderSection';
import PageContent from '../sections/how-it-works/PageContent';
import Join from '../sections/Join';
import FooterNew from '../sections/common/FooterWithoutFormFactorHook';

const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    -webkit-backface-visibility: hidden;
  }
`;

const AboutUs: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <MetaTags
        title='How it Works | illume'
        description={
          'Celebrate together with illume. illume makes it easy to send the best ecards and gifts for birthdays, anniversaries, or any occasion. Getting started is easy. '
        }
      />
      <Layout>
        <Background showTopBlob={false} />
        <Header />
        <HeaderSection />
        <PageContent />
        <Join />
        <FooterNew/>
      </Layout>
    </ThemeProvider>
  );
};

export default AboutUs;
