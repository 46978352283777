import React, { FC } from 'react';
import styled from 'styled-components';

import LinkButton from '../../components/LinkButton';

import createImg from '../../images/how-it-works/createLaptop.png';
import createImgMobile from '../../images/how-it-works/createMobile.png';
import customizeImg from '../../images/how-it-works/customizeLaptop.png';
import customizeImgMobile from '../../images/how-it-works/customizeMobile.png';
import selectImg from '../../images/how-it-works/selectLaptop.png';
import selectImgMobile from '../../images/how-it-works/selectMobile.png';
import inviteImg from '../../images/how-it-works/inviteLaptop.png';
import inviteImgMobile from '../../images/how-it-works/inviteMobile.png';
import sendImg from '../../images/how-it-works/sendLaptop.png';
import sendImgMobile from '../../images/how-it-works/sendMobile.png';

import { SEND_FREE_GROUPCARD_LINK } from '../../constants/links';

const Layout = styled.section`
  padding: 122px 0;
  display: flex;
  flex-direction: column;
  gap: 88px;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  justify-content: center;
  z-index: 2;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    gap: 160px;
    padding: 122px 0;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    gap: 228px;
    padding: 122px 0;
  }
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    flex-direction: row;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
  }
`;

const RowReversed = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    flex-direction: row-reverse;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row-reverse;
  }
`;

const Section = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  justify-content: space-between;
  min-height: 204px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    width: 50%;

    min-height: initial;
    padding: 36px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 50%;
    padding: 36px;
    min-height: initial;
  }
`;

const Title = styled.p`
  color: ${(props) => props.theme.colors.dirtOrange};
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  padding: 0 0 16px 0;
  z-index: 1;

  width: 100%;
  max-width: 307px;
  align-self: center;
  text-align: left;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 16px;
    line-height: 20px;

    max-width: 100%;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
    line-height: 20px;

    max-width: 100%;
  }
`;

const Heading = styled.p`
  color: ${(props) => props.theme.colors.blackText};
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  text-align: left;
  max-width: 307px;
  align-self: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 44px;
    line-height: 55px;
    max-width: 310px;
    margin-right: auto;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 64px;
    line-height: 70px;
    max-width: 527px;
  }
`;

const Paragraph = styled.p`
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin: 24px 0;
  text-align: left;
  max-width: 300px;
  align-self: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 16px;
    line-height: 22px;
    max-width: 325px;
    margin-right: auto;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
    line-height: 26px;
    max-width: 480px;
  }
`;

const CreateLink = styled(LinkButton)`
  text-align: center;
  max-width: 300px;
  display: none;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    display: block;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    display: block;
  }

  &:hover {
    background-color: #e5a619;
  }
`;

const CreateLinkMobile = styled(LinkButton)`
  margin-top: 28px;
  display: block;
  width: 100%;
  max-width: 300px;
  align-self: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    display: none;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    display: none;
  }

  &:hover {
    background-color: #e5a619;
  }
`;

const Image = styled.img`
  display: none;
  -webkit-appearance: none;
  box-shadow: #f5f0e1 8px 8px 0px 0px;
  border-radius: 8px;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    display: inline-block;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    display: inline-block;
  }
`;

const ImageMobile = styled.img`
  display: inline-block;
  max-width: 300px;
  align-self: center;
  box-shadow: 8px 8px #f5f0e1;
  border-radius: 8px;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    display: none;
    max-width: 600px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    display: none;
    max-width: 600px;
  }
`;

const PageContent: FC = () => {
  return (
    <Layout>
      <RowReversed>
        <Section>
          <Title>Create</Title>
          <Heading>Get started in a couple of seconds</Heading>
          <Paragraph>
            Enter basic info for the group card, like who it’s for, the occasion, and a deadline and
            off you go! Yup, it’s that simple.
          </Paragraph>
          <CreateLink href={SEND_FREE_GROUPCARD_LINK}>Start now</CreateLink>
        </Section>
        <Section>
          <Image alt='Group card' src={createImg} />
          <ImageMobile alt='Group card' src={createImgMobile} />
          <CreateLinkMobile href={SEND_FREE_GROUPCARD_LINK}>Start now</CreateLinkMobile>
        </Section>
      </RowReversed>
      <Row>
        <Section>
          <Title>Customize</Title>
          <Heading>Let the creative juices flow</Heading>
          <Paragraph>
            Customize the card with a theme and then add your own personal flair using photos,
            videos, GIFs and more!
          </Paragraph>
          <CreateLink href={SEND_FREE_GROUPCARD_LINK}>Create your card</CreateLink>
        </Section>
        <Section>
          <Image alt='Group card notes' src={customizeImg} />
          <ImageMobile alt='Group card notes' src={customizeImgMobile} />
          <CreateLinkMobile href={SEND_FREE_GROUPCARD_LINK}>Create your card</CreateLinkMobile>
        </Section>
      </Row>

      <RowReversed>
        <Section>
          <Title>Select</Title>
          <Heading>Unique gifts from small businesses</Heading>
          <Paragraph>
            Stand out with a gift from our curated marketplace. We’ve done all the research so you
            don’t have to...you can’t go wrong!
          </Paragraph>
          <CreateLink href={SEND_FREE_GROUPCARD_LINK}>Start now</CreateLink>
        </Section>
        <Section>
          <Image alt='Group gifts' src={selectImg} />
          <ImageMobile alt='Group gifts' src={selectImgMobile} />
          <CreateLinkMobile href={SEND_FREE_GROUPCARD_LINK}>Start now</CreateLinkMobile>
        </Section>
      </RowReversed>
      <Row>
        <Section>
          <Title>Invite</Title>
          <Heading>Invite friends to contribute</Heading>
          <Paragraph>
            Generate a dynamic link and share it with friends so they can add their own messages and
            contribute funds for the gift!
          </Paragraph>
          <CreateLink href={SEND_FREE_GROUPCARD_LINK}>Create your card</CreateLink>
        </Section>
        <Section>
          <Image alt='Group celebration' src={inviteImg} />
          <ImageMobile alt='Group celebration' src={inviteImgMobile} />
          <CreateLinkMobile href={SEND_FREE_GROUPCARD_LINK}>Create your card</CreateLinkMobile>
        </Section>
      </Row>
      <RowReversed>
        <Section>
          <Title>Send</Title>
          <Heading>Make your recipient’s day</Heading>
          <Paragraph>
            When it’s time, we’ll notify you to send the card to your recipient! If necessary,
            they’ll enter their own shipping address.
          </Paragraph>
          <CreateLink href={SEND_FREE_GROUPCARD_LINK}>Start now</CreateLink>
        </Section>
        <Section>
          <Image alt='Virtual celebration' src={sendImg} />
          <ImageMobile alt='Virtual celebration' src={sendImgMobile} />
          <CreateLinkMobile href={SEND_FREE_GROUPCARD_LINK}>Start now</CreateLinkMobile>
        </Section>
      </RowReversed>
    </Layout>
  );
};

export default PageContent;
